import React from 'react';
import { styled } from '../../settings/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type {
  IconDefinition,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';

interface ButtonCircleIconProps
  extends React.PropsWithRef<JSX.IntrinsicElements['span']> {
  icon?: IconDefinition | IconProp;
  disabled?: boolean;
}

interface ContainerProps {
  disabled?: boolean;
}

const Container = styled.span<ContainerProps>`
  transition: all ease-in-out 0.1s;
  border-radius: 50rem;
  text-align: center;
  font-size: 0.6rem;
  line-height: 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export function ButtonCircleIcon({
  icon,
  onClick,
  disabled,
  children,
  className,
  ...props
}: ButtonCircleIconProps) {
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!disabled && onClick) onClick(e);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    // Preventing text selection on double click.
    e.preventDefault();
  };

  return (
    <Container
      role="button"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      className={classnames({ disabled }, className)}
      {...props}
    >
      <FontAwesomeIcon icon={icon as IconProp} />
    </Container>
  );
}
