import React, { useCallback } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { Label } from './Label';
import {
  FieldChangePayload,
  generatePlaceholderText,
} from '../../utils/forms.utils';
import { FieldValidator } from '../../core/validation/validator.type';
import { styled, theme } from '../../settings/theme';
import { ErrorMessage } from './ErrorMessage';
import { TooltipIcon } from './Tooltip';
import {
  IconDefinition,
  type IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { capitalizeString, formatPhone, parsePhone } from '../../utils/format';
import type { E164Number } from 'libphonenumber-js';

export type InputFieldChangeValue = string | number;

export interface InputFieldProps {
  // ID of InputField
  id: string;
  // Label for InputField
  label?: string;
  // Suffix: e.x. "Sq. Ft."
  suffix?: string;
  // Error string after validation
  error?: string;
  // Font Awesome Icon
  icon?: IconDefinition | IconProp;
  // On click handler
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  // Prefix for the input ID
  idPrefix?: string;
  // Make the field required
  required?: boolean;
  // Input type ("text" | "number")
  type?: 'text' | 'number' | 'phone';
  // Will disable the input field
  disabled?: boolean;
  // Absolute Position for Lock Icon
  disabledMargin?: number;
  // Additional classes for this component
  className?: string;
  // Additional classes for the input
  inputClassName?: string;
  // Placeholder text
  placeholder?: string;
  // Callback for when the input changes
  inputChange?: (payload: FieldChangePayload<InputFieldChangeValue>) => void;
  // The current value of the input
  value?: string | number;
  defaultValue?: string;
  // Tooltip messaging
  tooltip?: string;
  // Optional validation callback
  fieldValidator?: (id: string, value?: string | number) => FieldValidator;
  // Capitalize controlled value
  capitalize?: boolean;
}

interface IconContainerProps {
  disabledMargin: number | undefined;
}

interface InputProps {
  icon?: IconDefinition | IconProp;
  suffix?: string;
}

const Input = styled.input<InputProps>`
  background: ${({ theme }) => theme.color.white};
  border: none;
  border-bottom: 0.2rem solid ${({ theme }) => theme.color.lightGrey};
  font-size: ${({ theme }) => theme.fontSize.md};
  letter-spacing: 0.05rem;
  border-radius: 0;
  font-weight: 700;
  &::placeholder {
    color: ${({ theme }) => theme.color.lightGrey};
    text-transform: lowercase;
  }
  padding: 0.5rem ${({ suffix }) => (suffix ? '6' : '1')}rem 0.5rem
    ${({ icon }) => (icon ? '3' : '1')}rem;
  &:focus {
    border-bottom: 0.25rem solid ${({ theme }) => theme.color.primary};
    box-shadow: ${({ theme }) => theme.elevation.secondary};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.lightGrey};
  }
`;

const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  margin-top: -36px;
  margin-left: ${({ disabledMargin }) => disabledMargin}px;
  color: ${({ theme }) => theme.color.primary};
`;

const InputIcon = styled.div`
  left: 1rem;
`;

const Suffix = styled.div`
  --height: calc(1.5em + 1rem + 2px);
  text-align: right;
  width: 5rem;
  height: var(--height);
  margin-left: calc(100% - 5rem - 1rem);
  margin-top: calc(var(--height) * -1);
  line-height: var(--height);
  text-transform: lowercase;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.color.lightGrey};
`;

export const InputField = React.forwardRef<any, InputFieldProps>(
  (
    {
      id,
      type = 'text',
      icon,
      label,
      suffix,
      onClick,
      required = false,
      idPrefix = '',
      value = '',
      defaultValue,
      className,
      placeholder,
      inputChange = () => {},
      inputClassName,
      disabled = false,
      disabledMargin,
      error,
      tooltip,
      fieldValidator,
      capitalize = false,
    },
    ref,
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
      // Preventing from invalid characters to be inserted in the number input.
      if (type === 'number' && ['-', '+', 'e'].includes(event.key)) {
        event.preventDefault();
      }
    };

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (type === 'phone') {
          // By default, if a value is something like `"(123)"`
          // then Backspace would only erase the rightmost brace
          // becoming something like `"(123"`
          // which would give the same `"123"` value
          // which would then be formatted back to `"(123)"`
          // and so a user wouldn't be able to erase the phone number.
          // Working around this issue with this simple hack.

          let newValue = parsePhone(event.currentTarget.value);
          if (newValue === value) {
            newValue = newValue.slice(0, -1) as E164Number;
          }

          inputChange({
            id,
            value: (newValue || event.currentTarget.value) as string,
            event,
          });
        } else {
          let formattedValue;

          if (type === 'number') {
            formattedValue = Number(event.currentTarget.value);
          } else {
            formattedValue = event.currentTarget.value;
          }

          if (type !== 'number' && capitalize) {
            formattedValue = capitalizeString(formattedValue as string);
          }

          inputChange({ id, value: formattedValue, event });
        }
      },
      [value, id, inputChange, type],
    );

    const handleOnBlur = () => {
      if (fieldValidator) {
        fieldValidator(id, value).validate();
      }
    };

    const handleOnFocus = () => {
      if (error && fieldValidator) {
        fieldValidator(id, value).clear();
      }
    };

    const placeholderText = generatePlaceholderText(
      placeholder,
      label,
      required,
      'Type Here',
    );

    return (
      <div className={classnames('mb-4', className)}>
        {label && (
          <Label
            id={id}
            required={required}
            label={label}
            disabled={disabled}
          />
        )}
        {tooltip && (
          <TooltipIcon
            text={tooltip}
            className="d-inline-block"
            icon={faInfoCircle as IconProp}
            title="Info"
            color={theme.color.lightGrey}
          />
        )}
        {!disabled && <ErrorMessage error={error} />}
        <Input
          id={`${idPrefix}${id}`}
          ref={ref}
          icon={icon as IconProp}
          type={type}
          value={type !== 'phone' ? value : formatPhone(value as string)}
          onClick={onClick}
          suffix={suffix}
          disabled={disabled}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className={classnames(
            'form-control',
            inputClassName || 'form-control-lg',
          )}
          placeholder={placeholderText}
          autoComplete="off"
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />

        {icon && (
          <InputIcon className="position-relative">
            <IconContainer disabledMargin={disabledMargin}>
              <FontAwesomeIcon icon={icon} />
            </IconContainer>
          </InputIcon>
        )}

        {suffix && (
          <div className="float-right">
            <Suffix>{suffix}</Suffix>
          </div>
        )}

        {disabled && disabledMargin && (
          // TODO: find a better way to dynamically add margin
          <div className="position-relative">
            <IconContainer disabledMargin={disabledMargin}>
              <FontAwesomeIcon icon={faLock as IconProp} />
            </IconContainer>
          </div>
        )}
      </div>
    );
  },
);
