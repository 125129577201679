import { styled } from '../../settings/theme';
import { ButtonCircleIcon } from '../form/ButtonCircleIcon';

export const Button = styled.button`
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.4rem 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  transition: ${({ theme }) => theme.transition.primary};
  color: ${({ theme }) => theme.color.primary};
  background-color: transparent;
  border: none;
`;

export const ButtonRounded = styled(Button)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};

  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  border: 0.2rem solid ${({ theme }) => theme.color.primary};

  &:hover {
    background-color: ${({ theme }) => theme.color.transparent};
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ButtonJumbo = styled(Button)`
  width: 100%;
  max-width: 18rem;
  min-width: 6rem;
  font-size: ${({ theme }) => theme.fontSize.primary};
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.8rem 0;
  letter-spacing: 0.1rem;
  border: 0.2rem solid ${({ theme }) => theme.color.transparent};
`;

export const ButtonJumboRoundedLight = styled(ButtonJumbo)`
  background-color: ${({ theme }) => theme.color.transparent};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.primary};
  border: 0.2rem solid ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.white};
  }
`;

export const ButtonJumboRounded = styled(ButtonJumbo)`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.primary};
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  border: 0.2rem solid ${({ theme }) => theme.color.primary};

  &:hover {
    background-color: ${({ theme }) => theme.color.transparent};
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ButtonCircleIconLight = styled(ButtonCircleIcon)`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  border: 0.2rem solid ${({ theme }) => theme.color.primary};
  box-shadow: ${({ theme }) => theme.elevation.primary};
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.primary};
  }
  &.disabled,
  &.disabled:hover {
    opacity: 0.5;
    cursor: not-allowed !important;
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.white};
  }
`;
