import React, { useEffect } from 'react';
import { styled, theme } from '../settings/theme';
import { ButtonJumboRounded } from '../components/styled/Button.styled';
import { Section } from '../components/layout/Section';
import { Image } from 'react-bootstrap';
import { PageLayout } from '../components/layout/PageLayout';
import { Testimonial } from '../components/shared/Testimonial';
import { Underline } from '../components/styled/Typography.styled';
import { MovingQuoteForm } from '../components/moving-quote-form/MovingQuoteForm';

const Heading = styled.span`
  font-size: 2.5rem;
  display: block;
  font-weight: 700;
  @media (max-width: 767.98px) {
    & {
      font-size: 1.6rem;
    }
  }
`;

const Subheading = styled.span`
  font-size: 1.25rem;
  display: block;
  font-weight: 700;
  @media (max-width: 767.98px) {
    & {
      font-size: 0.85rem;
    }
  }
`;

export default function Home() {
  const zipInputRef = React.createRef<HTMLInputElement>();

  const focus = () => {
    zipInputRef?.current?.focus();
  };

  const handleButtonClick = () => {
    window.scrollTo(0, 0);
    focus();
  };

  useEffect(() => {
    focus();
  }, []);

  return (
    <PageLayout onButtonClick={handleButtonClick}>
      <div className="container-fluid">
        <Section
          spacing="1rem"
          className="text-center"
          backgroundSize="cover"
          backgroundImage="/images/night-movers.svg"
          backgroundPosition="bottom left"
        >
          <div className="container">
            <div className="row justify-content-center">
              <MovingQuoteForm ref={zipInputRef} />
            </div>
          </div>
        </Section>

        <Section
          spacing="3rem"
          className="text-center"
          backgroundColor={theme.color.lighterBlue}
        >
          <h2>
            100% Satisfaction, <Underline>Guaranteed.</Underline>
          </h2>
          <div className="row justify-content-center">
            <Testimonial
              name="Stacey V."
              score={5}
              location="Hollywood, CA"
              className="col-lg-4 col-md-6 col-sm-12"
            >
              My wife and I found the best moving providers through the moving
              cost calculator. They were incredibly considerate with our items,
              blanketing and wrapping everything that needed, and we got a great
              price!
            </Testimonial>
            <Testimonial
              name="Tami S."
              score={5}
              location="Studio City, CA"
              className="col-lg-4 col-md-6 col-sm-12"
            >
              I got multiple quotes from different movers in my area and booked
              a move on the same day. I was able to save hundreds of dollars
              just by comparing rates! I would definitely use their services
              again and recommend it to others!
            </Testimonial>
            <Testimonial
              name="Rodgers T."
              score={5}
              location="Bothell, WA"
              className="col-lg-4 col-md-6 col-sm-12"
            >
              I just had the easiest move, I got matched with a mom & pop moving
              company that gave me a great price and a white-glove service. It
              feels great to support local businesses and save money at the same
              time!
            </Testimonial>
          </div>
        </Section>

        <Section
          fullscreen
          className="text-center"
          backgroundImage="/images/trucks-2.svg"
          backgroundPosition="bottom"
        >
          <h2 className="mb-5 text-lg-left">
            <Underline>Long Distance</Underline> Moving Services.
          </h2>
          <div className="row">
            <div className="col-lg-6 text-lg-left">
              <h3 className="text-lg-left">
                Moving to a different state?
                <br />
                Our long distance moving providers will help you pack, store and
                unpack, starting at <Underline>$997</Underline>
              </h3>
              <ButtonJumboRounded className="my-5" onClick={handleButtonClick}>
                Get My Quote
              </ButtonJumboRounded>
            </div>
          </div>
        </Section>

        <Section
          fullscreen
          className="text-center"
          backgroundImage="/images/clouds-opacity.svg"
          backgroundPosition="bottom"
        >
          <h2 className="mb-5">
            <Underline>Local</Underline> Moving Services.
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 px-5">
              <img
                src="/images/local-move-2.svg"
                alt="Local Moving Cost Calculator"
              />
            </div>
            <div className="col-lg-6 text-lg-left">
              <h3>
                Moving close to home?
                <br />
                Our local movers will help you move,
                <br />
                Starting at <Underline>$65</Underline> per hour.
              </h3>
              <ButtonJumboRounded className="my-5" onClick={handleButtonClick}>
                Get My Quote
              </ButtonJumboRounded>
            </div>
          </div>
        </Section>

        <Section spacing="6rem" className="text-center">
          <div className="col-12">
            <h2 className="mb-5">
              Our <Underline textShadow>Nation-Wide</Underline> Network of
              Moving Providers.
            </h2>
            <div className="row mb-5">
              <div className="col">
                <Heading>48</Heading>
                <Subheading>US States</Subheading>
              </div>
              <div className="col">
                <Heading>1K+</Heading>
                <Subheading>Cities</Subheading>
              </div>
              <div className="col">
                <Heading>200+</Heading>
                <Subheading>Moving Companies</Subheading>
              </div>
            </div>
            <Image
              src="/images/map-opacity.svg"
              alt="Moving Cost Calculator - Nationwide"
              fluid
            />
          </div>
        </Section>

        <Section
          spacing="6rem"
          className="text-center"
          backgroundSize="50rem"
          backgroundImage="/images/truck-opacity.svg"
          backgroundPosition="bottom right"
        >
          <MovingQuoteForm idPrefix="form2" />
        </Section>
      </div>
    </PageLayout>
  );
}
