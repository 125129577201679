import React from 'react';

export interface FieldChangePayload<ValueType = any> {
  // ID of Field
  id: string;
  // Change Payload Value
  value: ValueType | undefined;
  // Event object of payload
  event?: React.ChangeEvent | React.SyntheticEvent<unknown>;
}

export const generatePlaceholderText = (
  placeholder?: string | number,
  label?: string,
  required?: boolean,
  defaultText?: string,
) => {
  let placeholderText = defaultText;
  if (placeholder) {
    placeholderText = String(placeholder);
  } else if (label) {
    placeholderText = `Enter ${label}`;
  }
  return placeholderText;
};
