import { DateTime } from 'luxon';
import { AsYouType } from 'libphonenumber-js';
import { MoveSize } from '@prisma/client';
import { moveSizeTypes } from '../core/types/move-size.type';

export const formatDate = (isoDate: string) =>
  DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_HUGE);

export const formatJSDate = (date: Date) =>
  DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_HUGE);

export const formatPhone = (value?: string) =>
  value ? new AsYouType('US').input(value.replace('+1', '')) : '';

export const parsePhone = (value: string) => {
  const asYouType = new AsYouType('US');
  asYouType.input(value as string);
  return asYouType.getNumber()?.number;
};

export const formatMoveSize = (moveSize: MoveSize) =>
  moveSizeTypes[moveSize].name;

export const capitalizeString = (s: string) =>
  s
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
