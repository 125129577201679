import React from 'react';
import { InputField, InputFieldChangeValue } from '../form/InputField';
import { useValidation } from '../../core/validation/validator.utils';
import { useForm } from '../../features/form/hooks/useForm';
import { FieldChangePayload } from '../../utils/forms.utils';
import { QuoteForm } from '../../features/form/form.slice';
import { useRouter } from 'next/router';
import { routeTypes } from '../../core/types/route.type';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { NavButtons } from '../form/NavButtons';
import { fromZipSchema } from '../../core/validation/schemas/from-zip.schema';
import { Card } from '../layout/Card';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface QuoteFormProps {
  idPrefix?: string;
}

export const MovingQuoteForm = React.forwardRef<any, QuoteFormProps>(
  ({ idPrefix = '' }, ref) => {
    const { setFormField, getForm } = useForm();
    const quoteForm = getForm('quote');
    const router = useRouter();
    const { fromZip } = quoteForm;

    const { fieldValidator, validationErrorsById, validateAll } = useValidation(
      quoteForm,
      fromZipSchema,
    );

    const handleInputChange = ({
      id,
      value,
    }: FieldChangePayload<InputFieldChangeValue>) => {
      setFormField({ formId: 'quote', fieldId: id as keyof QuoteForm, value });
    };

    const handleButtonClick = async () => {
      const valid = validateAll();
      if (!valid) {
        console.log('fields invalid.');
        return;
      } else {
        await router.push(routeTypes.QUOTE.MOVE_SIZE.route);
      }
    };

    return (
      <Card className="col-xl-7 col-lg-8 col-md-12 col-12">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-md-10 col-sm-10">
            <div className="large p-3">
              <a
                className="d-flex flex-column justify-content-center align-items-center font-weight-bold"
                href="tel:888-555-5555"
              >
                <span>Call To Get A Free Quote</span>1-888-555-5555
              </a>
            </div>
            {!idPrefix ? (
              <h1 className="mb-4">
                Moving Cost
                <br />
                Calculator
              </h1>
            ) : (
              <h2 className="mb-4">
                Calculate Your
                <br />
                Moving Cost
              </h2>
            )}

            <h3 className="mb-4">
              {idPrefix
                ? 'Local Move rates starting at $65/hour.'
                : 'Local Move starting at $65/hour.'}
              <br />
              <br />
              {idPrefix
                ? 'Long Distance Move rates starting at $997.'
                : 'Long Distance Move starting at $997.'}
            </h3>
            <div>
              <InputField
                id="fromZip"
                ref={!idPrefix ? ref : undefined}
                idPrefix={idPrefix}
                icon={faMapMarkerAlt as IconProp}
                label="Where are you moving from?"
                placeholder="Enter Zip Code"
                value={fromZip}
                error={validationErrorsById.fromZip}
                fieldValidator={fieldValidator}
                inputChange={handleInputChange}
                className="text-left flex-fill w-md-50 my-5"
                required
              />

              <NavButtons
                className="mb-4"
                nextText="Get rates"
                onNext={handleButtonClick}
              />
              <h3 className="mb-4">
                {!idPrefix
                  ? 'Enter Zip · View Rates & Availability Now'
                  : 'Enter Zip · Calculate Cost & Compare Prices'}
              </h3>
            </div>
          </div>
        </div>
      </Card>
    );
  },
);
