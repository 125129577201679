// Moving from form schema
import {
  isUSZipCodeStrategy,
} from '../validator.strategy';
import { ValidationSchema } from '../validator.type';

export const fromZipSchema = {
  fromZip: {
    strategies: [isUSZipCodeStrategy],
    required: true,
  },
} as ValidationSchema;
