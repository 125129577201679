import React from 'react';
import classnames from 'classnames';
import { ButtonJumbo, ButtonJumboRounded } from '../styled/Button.styled';

export interface NavButtonsProps {
  onBack?: () => void;
  onNext?: () => void;
  nextText?: string;
  backText?: string;
  className?: string;
  nextDisabled?: boolean;
}

export const NavButtons = ({
  className,
  onBack,
  onNext,
  nextText,
  backText,
  nextDisabled,
}: NavButtonsProps) => {
  return (
    <div className={classnames(className, 'mt-3')}>
      <div className="row">
        {onNext && (
          <div className="col-12 text-center">
            <ButtonJumboRounded onClick={onNext} disabled={nextDisabled}>
              {nextText || 'Next'}
            </ButtonJumboRounded>
          </div>
        )}
        {onBack && (
          <div className="col-12 text-center my-1">
            <ButtonJumbo onClick={onBack}>{backText || 'Back'}</ButtonJumbo>
          </div>
        )}
      </div>
    </div>
  );
};
