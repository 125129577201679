import React from 'react';
import { styled } from '../../settings/theme';

export interface UnderlineProps {
  children: React.ReactNode;
  textShadow?: boolean;
}

export const Inner = styled.span<UnderlineProps>`
  background-image: linear-gradient(
    ${({ theme }) => theme.color.red},
    ${({ theme }) => theme.color.red}
  );
  background-position: 0 1.15em;
  background-repeat: repeat-x;
  background-size: 1px 5px;
  ${({ textShadow }) =>
    textShadow
      ? 'text-shadow: -2px -2px white, -2px 2px white, 2px -2px white, 2px 2px white;'
      : ''}
`;

export const Underline = ({ children, textShadow }: UnderlineProps) => {
  return <Inner textShadow={textShadow}>{children}</Inner>;
};
